import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fe0c53ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "d-flex" }
const _hoisted_4 = {
  key: 0,
  class: "fev_text5 me-2"
}
const _hoisted_5 = {
  key: 1,
  class: "fev_text5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.getAlign)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["d-flex w-100", _ctx.left ? 'justify-content-start' : 'justify-content-center'])
    }, [
      _createElementVNode("h1", {
        class: _normalizeClass(["fw_700 name", {'mb-0' : _ctx.distanceMeters || _ctx.sexualOrientation}]),
        innerHTML: _ctx.getText
      }, null, 10, _hoisted_1),
      (_ctx.getAge)
        ? (_openBlock(), _createElementBlock("h1", {
            key: 0,
            class: _normalizeClass(["fw_700", {'mb-0' : _ctx.distanceMeters || _ctx.sexualOrientation}]),
            innerHTML: _ctx.getAge
          }, null, 10, _hoisted_2))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.distanceMeters > 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$filters.kmFromMeters(_ctx.distanceMeters)), 1))
        : _createCommentVNode("", true),
      (_ctx.sexualOrientation)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$localizationService.getLocalizedValue("app_" + _ctx.sexualOrientation, _ctx.sexualOrientation)), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}