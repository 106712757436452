
import { Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import SpinnerContainer from '@/components/standard/spinnerContainer.vue';
import store from './store';
import { StorageServices } from './services/StorageServices';
import { AppUserClient, BackofficeLocalizationClient, LocalizationClient, LocalResourceContryClient } from './services/Services';
import { LoginServices } from './services/LoginServices';
import { assignViewWithServices } from './utility';

@Options({
    components: {
        SpinnerContainer
    }
})
export default class App extends Vue {

    loaded = false;

    created(){
        assignViewWithServices(this);
        store.state.loggedUser = StorageServices.getLoggedUser();

        Promise.all([
            BackofficeLocalizationClient.getBackofficeDictionary(),
            LocalizationClient.getEnabledLanguages(),
            LocalResourceContryClient.getExtendedCountries(),
            AppUserClient.getAppConfiguration(),
        ]).then( x => {
            store.state.consts.localizedValues = x[0];
            store.state.consts.enabledLanguages = x[1];
            store.state.consts.extendedCountries = x[2];
            store.state.consts.config = x[3];
            this.loaded = true;

            this.checkLogin();
        })
    }

    checkLogin(){
        var token = StorageServices.getLoginToken();
        var refreshToken = StorageServices.getLoginRefreshToken();
        
        LoginServices.LoginCallback(token, refreshToken);
    }

    @Watch("$store.state.showSpinner")
    disableScroll(){
        var el = document.getElementById("body");
        
        if(el == null)
          return;
        
        if(store.state.showSpinner > 0)
            el.classList.add("overflow-hidden");
        else
            el.classList.remove("overflow-hidden");
    }

}
