import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30ec3262"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "premium_service" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "mb-3",
      src: _ctx.premiumService.imageUrl
    }, null, 8, _hoisted_2),
    _createElementVNode("span", {
      class: "fev_text5 fw_800 mb-2",
      innerHTML: _ctx.$localizationService.getTextFromLocalizedValue(_ctx.premiumService.title)
    }, null, 8, _hoisted_3),
    _createElementVNode("span", {
      class: "fev_text6",
      innerHTML: _ctx.$localizationService.getTextFromLocalizedValue(_ctx.premiumService.description)
    }, null, 8, _hoisted_4)
  ]))
}