import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["param", "sortable"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "column",
    param: _ctx.param,
    sortable: _ctx.sortable,
    style: _normalizeStyle(_ctx.param && _ctx.sortable ? 'cursor: pointer' : '')
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 12, _hoisted_1))
}