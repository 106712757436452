
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { StackedChartDataset } from '@/viewModel';
import Chart from 'chart.js/auto';

@Options({})
export default class StackedChart extends Vue {

    @Prop({
        default: []
    }) dataLabels: string[];
    @Prop({
        default: []
    }) datasetsStacked: StackedChartDataset[];

    created() {
    }

    mounted(){       
        this.stackedBars();
    }

    stackedBars(){
        var canvas : any = this.$refs.stacked;
        var ctx = canvas.getContext('2d');

        var myChart = new Chart(ctx, {
            type: 'bar',  
            data: {
                labels: this.dataLabels,
                datasets: this.datasetsStacked
            },   
            options: {
                scales: {
                    x: {
                        stacked: true, 
                    },
                    y: {
                        stacked: true, 
                    }
                }
            }
        });
    }
}
