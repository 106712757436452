import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "modal bg_white flex_centered column" }
const _hoisted_2 = { class: "page_padding text-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "flex_centered" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.img)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "mb-3",
            src: './img/' + _ctx.img
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("h2", {
        class: "fw_700 text-center mb-3",
        innerHTML: _ctx.text
      }, null, 8, _hoisted_4),
      _createElementVNode("span", {
        class: "fev_text4 text-center",
        innerHTML: _ctx.subtitle
      }, null, 8, _hoisted_5)
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.confirmCb)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: _normalizeClass(["fev_button big transparent bg_brand_error bg_on_text", _ctx.denyCb && _ctx.confirmCb ? 'w-50' : 'w-100']),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.confirmCb && _ctx.confirmCb(...args)))
          }, [
            _createElementVNode("span", { innerHTML: _ctx.confirm }, null, 8, _hoisted_7)
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.denyCb)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: _normalizeClass(["fev_button big transparent bg_brand bg_on_text", _ctx.denyCb && _ctx.confirmCb ? 'w-50' : 'w-100']),
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.denyCb && _ctx.denyCb(...args)))
          }, [
            _createElementVNode("span", { innerHTML: _ctx.deny }, null, 8, _hoisted_8)
          ], 2))
        : _createCommentVNode("", true)
    ])
  ]))
}