
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import BaseServerFilterTable from '@/components/customTable/baseServerFilterTable';
import { Prop, Watch } from 'vue-property-decorator';
import store from '@/store';

@Options({
    components: {
    }
})
export default class AffinityListComponent extends BaseServerFilterTable {

    @Prop() userIdentifier;
    @Prop() initCallback;

    list: OM.ExperienceBackofficeListVM[] = [];
    lang: string = store.state.loggedUser.preferredCulture;
    affinityStatus = OM.AffinityStatus;

    created() {
    }

    init = function(){
        var tempRequestFilter = this.requestFilter;
        
        if(this.userIdentifier)
            tempRequestFilter.identifier = this.userIdentifier;

        this.initCallback(tempRequestFilter, this.setPagedResponse);
    }

    setPagedResponse(pagedResponse: OM.PagedResponseOfExperienceBackofficeListVM){
        this.totalItems = pagedResponse.totalItemsCount;
        this.list = pagedResponse.items;
    }

    rowClicked(vm: OM.BackofficeAffinityListVM){
        this.$emit("rowClicked", vm);
    }

    getNames(vm: OM.BackofficeAffinityListVM){

        var appUsers = vm.replies.map( x => x.appUser.text);

        var result = "";
        for(var i = 0; i < appUsers.length; i++){
            result += appUsers[i];

            if(i < appUsers.length - 1)
                result += " - ";
        }

        return result;
    }
}
