import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"
import _imports_0 from '@/assets/img/attention_white.svg'


const _hoisted_1 = { class: "reportQuestion" }
const _hoisted_2 = {
  key: 0,
  src: _imports_0
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showAlertImage)
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ]))
}