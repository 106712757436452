
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from "@/Model";

@Options({})
export default class imageAndQuestions extends Vue {

    @Prop() profileQuestion: OM.ProfileQuestion | OM.Answer;
    @Prop() image: string;

    myIndexSelected = 3;

    get backgroundImage(){
        return this.image;
    }

    getAffinityAnswer(index: number){
        var tempProfileQuestion = this.profileQuestion as OM.Answer;

        if(tempProfileQuestion.answerIndex || tempProfileQuestion.answerIndex == 0)
            return tempProfileQuestion.answersPercentage[index];
        
        return 100 - 25 * index;
    }

    getButtonClass(index: number){
        var classes = "";

        var tempProfileQuestion = this.profileQuestion as OM.Answer;
        if(tempProfileQuestion.answerIndex == null || tempProfileQuestion.answerIndex != index)
            classes += "box_shadow ";

        if(tempProfileQuestion.answerIndex == index)
            classes += "blue ";

        return classes;
    }

}
