
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {
    }
})
export default class HeaderFeven extends Vue {

    @Prop({
        default: true
    }) white: boolean;

}
