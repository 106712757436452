import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/audio_play.png'
import _imports_1 from '@/assets/img/audio_pause.png'


const _withScopeId = n => (_pushScopeId("data-v-6ee56844"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "audio_player" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "player" }
const _hoisted_4 = { class: "time" }
const _hoisted_5 = { class: "d-flex justify-content-between" }
const _hoisted_6 = { class: "time__current" }
const _hoisted_7 = { class: "time__full" }
const _hoisted_8 = {
  class: "line__current",
  ref: "currentLine"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("audio", {
      ref: "player",
      preload: "auto",
      onTimeupdate: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateTime && _ctx.updateTime(...args))),
      onEnded: _cache[1] || (_cache[1] = ($event: any) => (_ctx.stop())),
      onLoadeddata: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.setEndTime && _ctx.setEndTime(...args)))
    }, [
      _createElementVNode("source", {
        src: _ctx.source,
        type: "audio/mpeg"
      }, null, 8, _hoisted_2)
    ], 544),
    _createElementVNode("div", _hoisted_3, [
      (!_ctx.isPlaying)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _imports_0,
            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.togglePlay()), ["stop","prevent"]))
          }))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: _imports_1,
            onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.togglePlay()), ["stop","prevent"]))
          })),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$filters.formatTimeFromSeconds(_ctx.currentTime)), 1),
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$filters.formatTimeFromSeconds(_ctx.elementEndTime)), 1)
        ]),
        _createElementVNode("div", {
          class: "time__line",
          ref: "timeline",
          onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop","prevent"])),
          onMousedown: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.setTimeMouse && _ctx.setTimeMouse(...args))),
          onMousemove: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.setTimeMouseMove && _ctx.setTimeMouseMove(...args))),
          onTouchstart: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.setTimeTouch && _ctx.setTimeTouch(...args))),
          onTouchmove: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.setTimeTouch && _ctx.setTimeTouch(...args)))
        }, [
          _createElementVNode("div", _hoisted_8, null, 512)
        ], 544)
      ])
    ])
  ]))
}