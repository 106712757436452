import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/feven_horizontal_white.svg'
import _imports_1 from '@/assets/img/feven_horizontal_blue.svg'


const _withScopeId = n => (_pushScopeId("data-v-4a912431"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header_feven" }
const _hoisted_2 = { class: "img_container" }
const _hoisted_3 = {
  key: 0,
  src: _imports_0
}
const _hoisted_4 = {
  key: 1,
  src: _imports_1
}
const _hoisted_5 = { class: "img_container d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "left", {}, undefined, true)
    ]),
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('center')))
    }, [
      _renderSlot(_ctx.$slots, "center", {}, () => [
        (_ctx.white)
          ? (_openBlock(), _createElementBlock("img", _hoisted_3))
          : (_openBlock(), _createElementBlock("img", _hoisted_4))
      ], true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "right", {}, undefined, true)
    ])
  ]))
}