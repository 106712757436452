import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["tabindex", "readonly"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    type: "time",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
    "on:update:modelValue": _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.emitModel && _ctx.emitModel(...args))),
    tabindex: _ctx.tabIndex,
    readonly: _ctx.readonly
  }, null, 40, _hoisted_1)), [
    [_vModelText, _ctx.localValue]
  ])
}