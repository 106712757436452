
import { Options, mixins, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class LoveFaceShadowBar extends Vue  {

  @Prop() text: string;

}
