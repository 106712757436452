
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class LocalizedText extends Vue {
    @Prop({ default: "span"}) type: string;
    @Prop({ default: "" }) localizedKey: string;
    @Prop({ default: "" }) text: string;
    @Prop({ default: "" }) prevText: string;
    @Prop({ default: "" }) afterText: string;
    @Prop({ default: "" }) object: any;

    localizedValues: OM.LocalizedEntity[] = [];
    translatedText: string = "";

    @Watch('localizedKey')
    @Watch('prevText')
    @Watch('afterText')
    @Watch('object')
    onLocalizedKey(next, prev) {
        this.setLocalizedText();
    }

    @Watch('prevText')
    @Watch('afterText')
    onAdditionalTextChange() {
        this.setLocalizedText();
    }

    unwatcher: () => void = null;
    mounted() {
        this.setLocalizedText();
        this.unwatcher = this.$store.watch(
            state => {
                return state.loggedUser.preferredCulture
            },
            () => { 
                this.setLocalizedText(); 
            }
        )
    }

    beforeUnmount(){
        this.unwatcher();
    }

    setLocalizedText() {
        this.translatedText = this.$localizationService.getLocalizedValue(this.localizedKey, this.text, this.afterText, this.prevText, this.object);
    }

}
