import * as OM from '@/Model';

export class Breadcrumb {
    title: string;
    link: string;
    constructor(){
        this.title = "";
        this.link = "";
    }
}

export var ExperienceStatus = {
    Pending: "Pending",
    Approved: "Approved",
    Refused: "Refused"
}

export var ExperiencePeriodVM = {
    weekend: "weekend",
    week: "week",
    month: "month"
}

export var ReferredType = {
    User: "User",
    Influencer: "Influencer"
}

export class StackedChartDataset {
    label: string;
    data: number[];
    backgroundColor: string;
    constructor(){
        this.label = "";
        this.data = [];
        this.backgroundColor = "";
    }
}