import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/users',
            component: { render: () => h(RouterView) },
            children: [
                {
                    path: '/users',
                    name: 'users',
                    component: () => import("./views/users.vue")
                },
                {
                    path: '/user-detail/:identifier',
                    name: 'user-detail',
                    component: () => import("./views/userDetail.vue")
                },
                {
                    path: '/user-detail/:identifier/experience-list',
                    name: 'user experience list',
                    component: () => import("./views/userExperienceList.vue")
                },
                {
                    path: '/user-detail/:identifier/affinity-list',
                    name: 'user affinity list',
                    component: () => import("./views/userAffinityList.vue")
                },
                {
                    path: '/user-detail/:identifier/chat-list',
                    name: 'user chat list',
                    component: () => import("./views/userChatList.vue")
                }
            ]
        },
        {
            path: '/reports',
            name: 'reports',
            component: () => import("./views/reports.vue")
        }
    ];
};