import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "border-0 h-100 w-100",
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.selectFile && _ctx.selectFile(...args)))
  }, [
    _createElementVNode("input", {
      hidden: "true",
      ref: "input",
      type: "file",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFileSelect && _ctx.onFileSelect(...args))),
      multiple: "false"
    }, null, 544)
  ]))
}