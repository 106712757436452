import { Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import * as OM from '@/Model';
import { Watch } from 'vue-property-decorator';

export default abstract class BaseServerFilterTable extends Vue {

    abstract init;
    
    tableRoot = null;
    requestFilter: OM.PagedRequestVM | OM.PagedRequestWithIdentifierVM = new OM.PagedRequestVM();
    totalItems: number = 0;
    currentPage: string = "";
    
    timeoutTime = 0;
    timeoutHandler;

    created(){
    }

    mounted() {
        this.currentPage = this.$route.name.toString();
        this.tableRoot = document.getElementById("table_root")
        window.addEventListener('popstate', this.mounteRequestFilter);
        
        setTimeout(() => {
            this.mounteRequestFilter();
        }, 0);
    }

    beforeDestroy() {
        window.removeEventListener('popstate', this.mounteRequestFilter);
    }

    @Watch("requestFilter", { deep: true })
    requestChanged(){
        clearTimeout(this.timeoutHandler);

        this.timeoutHandler = setTimeout(() => {
            if(this.timeoutTime == 0)
                this.timeoutTime = 1000;
                
            this.init();
        }, this.timeoutTime);
    }

    mounteRequestFilter(){
        let queryParams = this.$route.query || {};
        
        //paging
        let pagingCurrentPage = (queryParams['paging.currentPage'] || '') as string;
        let pagingItemsPerPage = (queryParams['paging.itemsPerPage'] || '') as string;
        let pagingSkip = (queryParams['paging.skip'] || '') as string;
        let pagingTake = (queryParams['paging.take'] || '') as string;

        if(pagingCurrentPage && pagingItemsPerPage && pagingSkip && pagingTake){
            this.requestFilter.paging = {
                currentPage: parseInt(pagingCurrentPage),
                itemsPerPage: parseInt(pagingItemsPerPage),
                skip: parseInt(pagingSkip),
                take: parseInt(pagingTake)
            }
        }

        // sortby
        let propertyName = (queryParams['sortBy.propertyName'] || '') as string;
        let ascending = (queryParams['sortBy.ascending'] || '') as string;
        
        if(propertyName && ascending){
            this.requestFilter.sortBy = {
                propertyName: propertyName,
                ascending: ascending == "true"
            }
        }

        //identifier
        let identifier = (queryParams['identifier'] || '') as string;

        if(identifier)
            (<OM.PagedRequestWithIdentifierVM>this.requestFilter).identifier = identifier;

        // Inizializzare la proprietà Filter
        let filter = {};

        for (let key in queryParams) {
            if (key.startsWith('filter[')) {
                let filterKey = key.substring('filter['.length, key.length - 1);
                let filterValues = Array.isArray(queryParams[key]) ? queryParams[key] : [queryParams[key]];
                filter[filterKey] = filterValues;
            }
        }
        
        this.requestFilter.filter = filter;
        this.initTableFilter();        
    }

    initTableFilter(){
        let headTds = this.tableRoot.querySelectorAll('.head_list .column');
        let headSpans = this.tableRoot.querySelectorAll('.head_list .column span');

        for(var i = 0; i < headTds.length; i++){
            var param = headTds[i].getAttribute("param");
            if(!param){
                headTds[i].classList.add("without_param")
            } else {
                if(headTds[i].getAttribute("sortable") == "true")
                    this.insertSort(headSpans[i], param);
                
                this.insertFilter(headTds[i], param);
            }
        }
        
        if(this.requestFilter.sortBy && this.requestFilter.sortBy.propertyName){
            var property = this.requestFilter.sortBy.propertyName;
            let target = this.tableRoot.querySelectorAll('.head_list .column[param="' + property + '"] span')[0];

            if(this.requestFilter.sortBy.ascending)
                target.classList.add("asc");
            else
                target.classList.add("desc");
        }
    }

    insertSort(element, param){
        if(!param)
            return;
        
        element.onclick = () => {
            this.$emit("changeSort", param)
        }
    }

    // sem
    insertFilter(element, param){
        if(!param)
            return;
        
        var input = document.createElement('input');
        input.type = "text";
    
        var child = element.appendChild(input);

        if(this.requestFilter.filter && this.requestFilter.filter[param])
            child.value = this.requestFilter.filter[param].toString();

        child.oninput = (value) => {
            var text = value.target.value;

            if(!text){
                delete this.requestFilter.filter[param];
                return;
            }

            this.requestFilter.filter[param] = [];
            this.requestFilter.filter[param].push(text);
        }
    }

    changeSort(property: string){
        let headSpans = this.tableRoot.querySelectorAll('.head_list .column span');
        headSpans.forEach(element => {
            element.classList.remove("asc");
            element.classList.remove("desc");
        });

        let target = this.tableRoot.querySelectorAll('.head_list .column[param="' + property + '"] span')[0];

        if(!property)
            return;
        
        if(this.requestFilter.sortBy == null)
            this.requestFilter.sortBy = new OM.SortByParameter();

        if(this.requestFilter.sortBy.propertyName != property){
            this.requestFilter.sortBy.propertyName = property;
            this.requestFilter.sortBy.ascending = true;
            target.classList.add("asc");
            return;
        } 

        if(this.requestFilter.sortBy.ascending){
            this.requestFilter.sortBy.ascending = false;
            target.classList.add("desc");
            return;
        } 

        this.requestFilter.sortBy = null;
    }

    getClass(property: string){
        if(this.requestFilter.sortBy == null || this.requestFilter.sortBy.propertyName != property)
            return "";

        if(this.requestFilter.sortBy.ascending)
            return "asc";

        return "desc";
    }

}