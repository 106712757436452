
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class PersonInfo extends Vue {

    @Prop({
        default: false
    }) left: boolean;

    @Prop({
        default: ""
    }) name: string;

    @Prop({
        default: ""
    }) birthDate: string;

    @Prop({
        default: 0
    }) distanceMeters: number;

    @Prop({
        default: ""
    }) sexualOrientation: string;

    @Prop({
        default: ""
    }) affinityRateText: string;

    @Prop({
        default: 0
    }) minimumAffinityRate: number;
    
    get getText(){
        var ris = this.name.trim();

        return ris;
    }

    get getAge(){
        if(!this.birthDate)
            return "";

        return ", " + this.$filters.age(this.birthDate);
    }

    get getAlign(){
        var ris = "d-flex flex-column "

        if(this.left)
            return ris + "align-items-start";
        
        return ris + "align-items-center";
    }
}
