import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/influencer-list',
            component: { render: () => h(RouterView) },
            children: [
                {
                    path: '/influencer-list',
                    name: 'influencer list',
                    component: () => import("./views/influencerList.vue")
                },
                {
                    path: '/influencer-detail/:identifier?',
                    name: 'influencer detail',
                    component: () => import("./views/influencerDetail.vue")
                }
            ]
        }
    ];
};