
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class PremiumService extends Vue {

    @Prop() premiumService: OM.PremiumService;

}
