
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ExperiencePeriodVM } from '@/viewModel';

@Options({
    components: {
    }
})
export default class ShowExperiencePeriodSelected extends Vue {

    @Prop() periodSelected = "";

    created(){
    }

    get isWeekendActive() {
        return this.periodSelected == ExperiencePeriodVM.weekend;
    }
    get isWeekActive() {
        return this.periodSelected == ExperiencePeriodVM.week;
    }
    get isMonthActive() {
        return this.periodSelected == ExperiencePeriodVM.month;
    }
    
}
