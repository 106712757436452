
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import audioPlayer from './audioPlayer.vue';
import * as OM from '@/Model';
import { getUniversalDate } from '@/utility';

@Options({
    components: {
        audioPlayer
    }
})
export default class ExperienceResume extends Vue {

    @Prop({
        default: () => new OM.ExperienceDetailVM()
    }) experience: OM.ExperienceDetailVM;

    @Prop({
        default: () => new OM.ExperienceCategory()
    }) categorySelected: OM.ExperienceCategory;

    @Prop() audioSrc = "";

    created(){
    }

    getUniversalDate(date: string){
        return getUniversalDate(date);
    }

    get getAge(){
        if(!this.experience.creatorBirthDate)
            return "";

        return ", " + this.$filters.age(this.experience.creatorBirthDate);
    }

    goToUser() {
        this.$opModal.closeLast();
        this.$router.push("/user-detail/" + this.experience.creator.identifier);
    }

}
