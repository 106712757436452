import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value", "readonly", "tabindex"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_localizedPlaceholder = _resolveDirective("localizedPlaceholder")!

  return _withDirectives((_openBlock(), _createElementBlock("input", {
    type: "mail",
    value: _ctx.localValue,
    onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.localValue = $event.target.value)),
    readonly: _ctx.readonly,
    tabindex: _ctx.tabIndex
  }, null, 40, _hoisted_1)), [
    [_directive_localizedPlaceholder, {key: _ctx.placeholderKey, value: _ctx.placeholderValue, prevText: _ctx.placeholderPrevText, afterText: _ctx.placeholderAfterText }]
  ])
}