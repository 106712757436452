
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AbuseClient } from '@/services/Services';
import InsertTextModal from '@/components/modals/insertTextModal.vue';
import InfoModal from '@/components/modals/infoModal.vue';
import * as OM from '@/Model';

@Options({
    components: {
        InfoModal
    }
})
export default class ReportQuestion extends Vue {

    @Prop() reportSuccessCb;

    @Prop({
        default: ""
    }) matchIdentifier: string;

    @Prop({
        default: -1
    }) questionIndex: number;

    @Prop({
        default:""
    }) appUserIdentifier: string;

    @Prop({
        default:""
    }) experienceIdentifier: string;

    @Prop({
        default: true
    }) showAlertImage: boolean;

    @Prop({
        default:"app_PerchéVuoiSegnalareQuestaPersona"
    }) titleKey: string;

    @Prop({
        default:"Perché vuoi segnalare questa persona?"
    }) titleValue: string;

    created() {}

    reportQuestion() {
        this.$opModal.show(InsertTextModal, {
            titleKey: this.titleKey,
            titleValue: this.titleValue,
            inputPlaceholderKey: "app_reportingReason",
			inputPlaceholderValue: "Reporting reason...",
			buttonKey: "app_SendReport",
			buttonText: "Send report",
			value: "",
			callback: (updatedValue: string) => {
                var report = new OM.ReportRequestVm();
                report.motivation = updatedValue;
                report.matchIdentifier = this.matchIdentifier;
                report.matchQuestionIndex = this.questionIndex;
                report.experienceIdentifier = this.experienceIdentifier;
                report.appUserIdentifier = this.appUserIdentifier;

                AbuseClient.report(report)
                .then(x => {
                    this.$opModal.show(InfoModal, {
                        img: "feven_squared_violet.svg",
                        text: this.$localizationService.getLocalizedValue("app_reportSuccess", "Report sent with success"),
                        confirm: this.$localizationService.getLocalizedValue("app_Ok", "Ok"),
                        confirmCb: () => {
                            this.$opModal.closeAll();
                            if(this.reportSuccessCb)
                                this.reportSuccessCb();
                        }
                    })
                })
                .catch(err => {
                    this.$opModal.show(InfoModal, {
                        img: "face_error.svg",
                        text: err.Message,
                        confirm: this.$localizationService.getLocalizedValue("app_Ok", "Ok"),
                        confirmCb: () => {
                            this.$opModal.closeLast();
                        }
                    })
                })
			}
        })
    }

}
