
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';

@Options({
    
})
export default class AudioPlayer extends Vue {

    @Prop() source: string;
    @Prop() title: OM.LocalizedValue;

    isPlaying: boolean = false;
    player: any;
    timeline: any;
    currentLine: any;
    currentTime: number = 0;
    elementEndTime: number = 0;

    mouseDown: boolean = false;
    mounted(){
        this.player = this.$refs.player;
        this.timeline = this.$refs.timeline;
        this.currentLine = this.$refs.currentLine;

        document.addEventListener("mouseup", this.setMouseUp);
        
        this.player.load();
    }

    setMouseUp(){
        this.mouseDown = false;
    }
    
    play(){
        this.isPlaying = true;
        this.player.play();
    }
    stop(){
        this.isPlaying = false;
        this.player.pause();
    }

    togglePlay(){
        this.isPlaying = !this.isPlaying;
        if(!this.isPlaying)
            this.player.pause();
        else 
            this.player.play();
    }

    setTimeMouseMove(e: MouseEvent){
        
        if(!this.mouseDown)
            return;

        this.setTimeMouse(e);
    }

    setTimeMouse(e: MouseEvent){
        this.mouseDown = true;
        var x: number;

        if (e.pageX) {
            x = e.pageX;
        } else {
            x = e.clientX;
        }

        this.setTime(x);
    }

    setTimeTouch(e: TouchEvent){
        var x: number;

        if (e.touches[0].pageX) {
            x = e.touches[0].pageX;
        } else {
            x = e.touches[0].clientX;
        }

        this.setTime(x);
    }

    setTime(x: number) {
        var setWidth;
        var nowWidth;
        var timeLineWidth = this.timeline.offsetWidth;
        this.player.pause();
       
        nowWidth = x - this.timeline.getBoundingClientRect().left;
        setWidth = (nowWidth * 100) / timeLineWidth;
        this.currentLine.style.width = setWidth + '%';
        this.player.currentTime = (setWidth * this.elementEndTime) / 100;
        if(this.isPlaying)
            this.player.play();
    };
    
    updateTime() {
        if (this.isPlaying) {
            var time = this.player.currentTime;
            this.currentTime = time;
            this.currentLine.style.width = (time * 100) / this.elementEndTime + '%';
        } else {
            this.currentTime = 0;
        }
    };

    setEndTime(e){
        this.player.currentTime = 1000000;

        setTimeout(() => {
            if(this.player.duration != Infinity){
                this.elementEndTime = this.player.duration;
            }

            this.player.currentTime = 0;
        }, 400);
    }

    beforeUnmount(){
        document.removeEventListener("mouseup", this.setMouseUp);
    }
}

