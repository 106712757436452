import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a7ad0f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = {
  key: 0,
  class: "input_title"
}
const _hoisted_3 = ["value", "rows"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_google_autocomplete = _resolveComponent("google-autocomplete")!
  const _component_localized_input = _resolveComponent("localized-input")!
  const _component_localized_text_area = _resolveComponent("localized-text-area")!
  const _component_localized_tinymce = _resolveComponent("localized-tinymce")!
  const _component_tiny_mce_view = _resolveComponent("tiny-mce-view")!
  const _component_select_field = _resolveComponent("select-field")!
  const _component_checkbox_field = _resolveComponent("checkbox-field")!
  const _directive_localizedPlaceholder = _resolveDirective("localizedPlaceholder")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.type != 'localized' && _ctx.type != 'localized-textarea' && _ctx.labelKey
            && !_ctx.fileModeIcon && _ctx.type != 'checkbox')
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          _createVNode(_component_localized_text, {
            class: "fev_text5 fw_600",
            localizedKey: _ctx.labelKey,
            text: _ctx.labelValue,
            prevText: _ctx.prevText,
            afterText: _ctx.afterText
          }, null, 8, ["localizedKey", "text", "prevText", "afterText"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.type == 'google')
      ? (_openBlock(), _createBlock(_component_google_autocomplete, _mergeProps({ key: 1 }, _ctx.$props, {
          modelValue: _ctx.googlePlace,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.googlePlace) = $event)),
          addressLocal: _ctx.modelValue.completeAddress,
          onDeleteAddress: _ctx.deleteAddress
        }), null, 16, ["modelValue", "addressLocal", "onDeleteAddress"]))
      : (_ctx.type == 'localized')
        ? (_openBlock(), _createBlock(_component_localized_input, _mergeProps({ key: 2 }, _ctx.$props, {
            modelValue: _ctx.localValue,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localValue) = $event))
          }), null, 16, ["modelValue"]))
        : (_ctx.type == 'localized-textarea')
          ? (_openBlock(), _createBlock(_component_localized_text_area, _mergeProps({ key: 3 }, _ctx.$props, {
              modelValue: _ctx.localValue,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localValue) = $event))
            }), null, 16, ["modelValue"]))
          : (_ctx.type == 'localized-tinymce')
            ? (_openBlock(), _createBlock(_component_localized_tinymce, _mergeProps({ key: 4 }, _ctx.$props, {
                modelValue: _ctx.localValue,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localValue) = $event))
              }), null, 16, ["modelValue"]))
            : (_ctx.type == 'tiny-mce')
              ? (_openBlock(), _createBlock(_component_tiny_mce_view, _mergeProps({ key: 5 }, _ctx.$props, {
                  modelValue: _ctx.localValue,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localValue) = $event))
                }), null, 16, ["modelValue"]))
              : (_ctx.type == 'select')
                ? (_openBlock(), _createBlock(_component_select_field, {
                    key: 6,
                    tabindex: _ctx.tabIndex,
                    labelKey: _ctx.labelKey,
                    labelValue: _ctx.labelValue,
                    required: _ctx.required,
                    placeholderKey: _ctx.placeholderKey,
                    placeholderValue: _ctx.placeholderValue,
                    prevText: _ctx.prevText,
                    afterText: _ctx.afterText,
                    "onUpdate:modelValue": _ctx.emitModel,
                    modelValue: _ctx.modelValue,
                    list: _ctx.list
                  }, null, 8, ["tabindex", "labelKey", "labelValue", "required", "placeholderKey", "placeholderValue", "prevText", "afterText", "onUpdate:modelValue", "modelValue", "list"]))
                : (_ctx.type == 'checkbox')
                  ? (_openBlock(), _createBlock(_component_checkbox_field, _mergeProps({ key: 7 }, _ctx.$props, {
                      modelValue: _ctx.localValue,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localValue) = $event))
                    }), null, 16, ["modelValue"]))
                  : (_ctx.type == 'textarea')
                    ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
                        key: 8,
                        class: "w-100 p-3",
                        value: _ctx.localValue,
                        rows: _ctx.rows,
                        onInput: _cache[6] || (_cache[6] = ($event: any) => (_ctx.localValue = $event.target.value))
                      }, null, 40, _hoisted_3)), [
                        [_directive_localizedPlaceholder, { key: _ctx.placeholderKey, value: _ctx.placeholderValue }]
                      ])
                    : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.inputComponent), _mergeProps({
                        key: 9,
                        "onUpdate:modelValue": [
                          _ctx.emitModel,
                          _cache[7] || (_cache[7] = ($event: any) => ((_ctx.localValue) = $event))
                        ],
                        modelValue: _ctx.localValue,
                        prefix: _ctx.localPrefix,
                        "onUpdate:prefix": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.localPrefix) = $event)),
                        onChangedPrefix: _ctx.updatePrefix
                      }, _ctx.$props), null, 16, ["onUpdate:modelValue", "modelValue", "prefix", "onChangedPrefix"]))
  ]))
}