import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "table_content_container four_column" }
const _hoisted_2 = { class: "body_content" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "column text_capitalize" }
const _hoisted_5 = { class: "column" }
const _hoisted_6 = { class: "column" }
const _hoisted_7 = { class: "column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_head_column = _resolveComponent("head-column")!
  const _component_table_filter = _resolveComponent("table-filter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_table_filter, {
      pagedRequest: _ctx.requestFilter,
      "onUpdate:pagedRequest": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.requestFilter) = $event)),
      totalItems: _ctx.totalItems,
      onChangeSort: _ctx.changeSort
    }, {
      default: _withCtx(() => [
        _createVNode(_component_head_column, { param: "createdOn" }, {
          default: _withCtx(() => [
            _createVNode(_component_localized_text, {
              localizedKey: "backoffice_CreatedOn",
              text: "CreatedOn"
            })
          ]),
          _: 1
        }),
        _createVNode(_component_head_column, null, {
          default: _withCtx(() => [
            _createVNode(_component_localized_text, {
              localizedKey: "backoffice_Utenti",
              text: "Utenti"
            })
          ]),
          _: 1
        }),
        _createVNode(_component_head_column, null, {
          default: _withCtx(() => [
            _createVNode(_component_localized_text, {
              localizedKey: "backoffice_GhostingLevel",
              text: "GhostingLevel"
            })
          ]),
          _: 1
        }),
        _createVNode(_component_head_column, { param: "isDeleted" }, {
          default: _withCtx(() => [
            _createVNode(_component_localized_text, {
              localizedKey: "backoffice_Cancellata",
              text: "Cancellata"
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["pagedRequest", "totalItems", "onChangeSort"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "body_list",
          key: item.identifier,
          onClick: ($event: any) => (_ctx.rowClicked(item.identifier))
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$filters.date(item.createdOn, "DD/MM/yyyy HH:mm")), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", null, _toDisplayString(_ctx.getNames(item)), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_localized_text, {
              localizedKey: 'backoffice_ghostinglevel_' + _ctx.ghostingLevels[item.ghostingLevel],
              text: _ctx.ghostingLevels[item.ghostingLevel]
            }, null, 8, ["localizedKey", "text"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", null, _toDisplayString(item.isDeleted), 1)
          ])
        ], 8, _hoisted_3))
      }), 128)),
      (_ctx.totalItems == 0)
        ? (_openBlock(), _createBlock(_component_localized_text, {
            key: 0,
            class: "no_table_content",
            localizedKey: "backoffice_NessunRisultato",
            text: "Nessun risultato"
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}