
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class InsertTextModal extends Vue {

    animate: boolean = false;

    @Prop({
        default: "textarea"
    }) inputType: string;
    @Prop() titleKey: string;
    @Prop() titleValue: string;
    @Prop() inputPlaceholderKey: string;
    @Prop() inputPlaceholderValue: string;
    @Prop() buttonKey: string;
    @Prop() buttonText: string;
    @Prop() callback: any;
    
    @Prop({
        default: ""
    }) value: string;
    localValue: string = "";

    created() {
        this.localValue = this.value;
        setTimeout(() => {
			this.animate = true;
		}, 100);
	}

    save() {
        this.callback(this.localValue);
    }

}
