import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "d-flex border-0" }
const _hoisted_2 = ["tabindex", "readonly"]
const _hoisted_3 = {
  key: 2,
  class: "ms-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      class: "flex_shrink_0 mt-1",
      type: "checkbox",
      tabindex: _ctx.tabIndex,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
      readonly: _ctx.readonly
    }, null, 8, _hoisted_2), [
      [_vModelCheckbox, _ctx.localValue]
    ]),
    (_ctx.color)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "ball ms-3",
          style: _normalizeStyle('background-color:' + _ctx.color)
        }, null, 4))
      : _createCommentVNode("", true),
    (_ctx.labelKey)
      ? (_openBlock(), _createBlock(_component_localized_text, {
          key: 1,
          class: "ms-2 my-auto fev_text5",
          localizedKey: _ctx.labelKey,
          text: _ctx.labelValue
        }, null, 8, ["localizedKey", "text"]))
      : _createCommentVNode("", true),
    (!_ctx.labelKey && _ctx.labelValue)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.labelValue), 1))
      : _createCommentVNode("", true)
  ]))
}