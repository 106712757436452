
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {
    }
})
export default class ChevronFixed extends Vue {

    
    @Prop() vertical: 'top' | 'bottom';
    @Prop() horizontal: 'right' | 'left';

    created() {
    }

}
