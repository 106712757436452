import { createStore } from 'vuex'
import { StorageServices } from '@/services/StorageServices';
import * as OM from '@/Model';

let store = {
    state: {
        showSpinner: 0,
        loggedUser: new OM.InternalUserVM(),
        consts: {
            localizedValues: <OM.LocalizedEntity[]>[],
            enabledLanguages: <string[]>[],
            extendedCountries: <OM.ExtendedCountry[]>[],
            config: new OM.SystemConfigurationVM(),
        },
        selectedLanguage: "",
        beforeNavCbs: [],
        afterNavCbs: []
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    },
    getters: {
    }
}

export default createStore(store);