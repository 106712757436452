import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f4d815d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "experienceList" }
const _hoisted_2 = { class: "d-flex align-items-center justify-content-between" }
const _hoisted_3 = { class: "fev_text5 d-flex align-items-center" }
const _hoisted_4 = { class: "fev_text7 fc_lightGrey" }
const _hoisted_5 = { class: "d-flex align-items-center my-3" }
const _hoisted_6 = { class: "radiusInfo bg_experience" }
const _hoisted_7 = { class: "radiusInfo category" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "fw_700 nowrap" }
const _hoisted_10 = { class: "title" }
const _hoisted_11 = { class: "fev_text5 d-block pb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_show_experience_period_selected = _resolveComponent("show-experience-period-selected")!
  const _component_audio_player = _resolveComponent("audio-player")!
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createElementVNode("span", {
          class: "text_underlined pointer",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToUser()))
        }, _toDisplayString(_ctx.experience.creator.text), 1),
        _createTextVNode(" " + _toDisplayString(_ctx.getAge), 1)
      ]),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$filters.date(_ctx.getUniversalDate(_ctx.experience.period.period.start), "DD/MM/YYYY HH:mm")) + " - " + _toDisplayString(_ctx.$filters.date(_ctx.getUniversalDate(_ctx.experience.period.period.end), "DD/MM/YYYY HH:mm")), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_show_experience_period_selected, {
          periodSelected: _ctx.experience.period.title
        }, null, 8, ["periodSelected"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.categorySelected.iconUrl)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.categorySelected.iconUrl
            }, null, 8, _hoisted_8))
          : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$localizationService.getTextFromValues(_ctx.categorySelected.title.values)), 1)
      ])
    ]),
    _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.experience.title), 1),
    (_ctx.audioSrc)
      ? (_openBlock(), _createBlock(_component_audio_player, {
          key: 0,
          source: _ctx.audioSrc,
          class: "mb-5"
        }, null, 8, ["source"]))
      : _createCommentVNode("", true),
    _createVNode(_component_localized_text, {
      localizedKey: "app_CosaFaremo:",
      text: "Cosa faremo:",
      class: "fev_text6 fw_700 d-block mb-3"
    }),
    _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.experience.description), 1)
  ]))
}