
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';

@Options({
})
export default class replyStatusHistoryModal extends Vue {

    @Prop({
        default: () => []
    }) model: OM.ReplyStatusHistory[];

    replyStatus = OM.ReplyStatus;
}
