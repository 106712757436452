
import { mixins, Options } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import WithModel from '../mixins/withModel';
import SelectField from './selectField.vue';
import SelectModal from '../modal/selectModal.vue';
import store from '@/store';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({
    components: {
        SelectField
    }
})
export default class inputTel extends mixins(WithModel) {
    @Prop() prefix: string;
    @Prop({ default: 0 }) tabIndex: number;
    @Prop({ default: "" }) placeholderKey: string;
    @Prop({ default: "" }) placeholderValue: string;
    @Prop({ default: "" }) placeholderPrevText: string;
    @Prop({ default: "" }) placeholderAfterText: string;
    @Prop({ default: false }) readonly: boolean;

    telList = [];
    localPrefix: string = "";

    created() {
        this.localPrefix = this.prefix;
        this.telList = store.state.consts.extendedCountries
            .map( x => {
                return {
                    callingCode: x.callingCodes[0].replaceAll(" ", ""),
                    name: x.name
                }
            })
            .filter( x => x.callingCode)
            .map(x => {
                return {
                    identifier: "+" + x.callingCode,
                    text: LocalizationServices.getLocalizedValue(x.name, x.name, " (+" + x.callingCode + ")", '')
                }
        })
        // this.telList = store.state.consts.extendedCountries.map(x => {
        //     return {
        //         identifier: "+ " + x.callingCodes[0],
        //         
        //     }
        // })
    }

    @Watch("localPrefix")
    emitPrefix() {
        this.$emit("update:prefix", this.localPrefix);
        // this.$emit("changedPrefix", this.localPrefix);
    }

}
