import { StorageServices } from '@/services/StorageServices';
import { CommonServices } from './CommonServices';
import { AppUserClient, LocalizationClient } from './Services';
import store from '@/store';
import * as OM from '@/Model';

class _LocalizationServices {

    constructor() {}

    SetLanguage(language: string) {
        var loggedUser = StorageServices.getLoggedUser();
        if(loggedUser.identifier) {
            AppUserClient.changeLanguageAndGetToken(language, false)
            .then( x => {
                loggedUser.preferredCulture = language;
                StorageServices.setLoggedUser(loggedUser);
                store.state.loggedUser = loggedUser;
                CommonServices.setAuthToken(x);
            }).catch( err => {
                loggedUser.preferredCulture = language;
                StorageServices.setLoggedUser(loggedUser);
                store.state.loggedUser = loggedUser;
            })
        }
        else {
            loggedUser.preferredCulture = language;
            StorageServices.setLoggedUser(loggedUser);
            store.state.loggedUser = loggedUser;

            store.state.showSpinner++;
            location.reload();
        }
    }

    SetSelectedLanguage(language: string) {
        store.state.selectedLanguage = language;
    }
    
    GetLanguage() {
        return store.state.selectedLanguage;
    }
    
    getLocalizedValue(key: string, value: string, afterText: string = '', prevText: string = '', object: any = null): string {
        var existing = store.state.consts.localizedValues.find(x => x.key == key);
        if (existing) {
            var loggedUser = StorageServices.getLoggedUser();
            var selectedCulture = loggedUser.preferredCulture;

            var result = prevText + existing.values[selectedCulture] + afterText;
            return replaceText(result, object);
        } else {
            var newValue = new OM.KeyValuePairOfStringAndString();
            newValue.key = key;
            newValue.value = value;
            
            LocalizationClient.saveLocalizedValue(newValue, false)
                .then(x => {
                    var newLocalizedValue = new OM.LocalizedEntity();
                    newLocalizedValue.key = key;
                    newLocalizedValue.identifier = "";
                    store.state.consts.enabledLanguages.forEach(x => {
                        newLocalizedValue.values[x] = value;
                    })
                    store.state.consts.localizedValues.push(newLocalizedValue);
                })
            var result = prevText + value + afterText;
            return replaceText(result, object);
        }
    }

    getTextFromValues(value: { [key: string]: string; }, customLang: string = "") {
        var lang = StorageServices.getLoggedUser().preferredCulture;
        if (customLang)
            lang = customLang;

        var keys = Object.keys(value);
        if (keys.indexOf(lang) == -1)
            return "";

        return value[lang];
    }

    getTextFromLocalizedValue(model: OM.LocalizedValue, customLang: string = "") {
        var values = model.values;

        if (customLang)
            return this.getTextFromValues(values, customLang);

        return this.getTextFromValues(values);
    }

    getEnabledLanguageList(afterText: string = '', prevText: string = ''): OM.TextIdentifier[] {

        if(!store.state.consts.enabledLanguages || store.state.consts.enabledLanguages.length == 0)
            return [];


        var result = store.state.consts.enabledLanguages.map( x => {
            var language = new OM.TextIdentifier();
            language.identifier = x;
            language.text = this.getLocalizedValue("backoffice_language_" + x, x, afterText, prevText);

            return language;
        })

        return result;
    }

    getActivePlaceTranslation(value: number){
        return this.getLocalizedValue("backoffice_activeplacetype_" + OM.ActivePlaceType[value], OM.ActivePlaceType[value]);
    }

    getActivePlaceType() {
        var result = [];
        var keys = Object.keys(OM.ActivePlaceType);
        keys.slice(0, keys.length / 2).forEach( el => {
            var vm = new OM.TextIdentifier();
            vm.identifier = el;
            vm.text = LocalizationServices.getLocalizedValue("backoffice_activeplacetype_" + OM.ActivePlaceType[el], OM.ActivePlaceType[el])

            result.push({...vm});
        })

        return result;
    }

    getOpeningType() {
        var result = [];
        var keys = Object.keys(OM.OpeningType);
        keys.slice(0, keys.length / 2).forEach( el => {
            var vm = new OM.TextIdentifier();
            vm.identifier = el;
            vm.text = LocalizationServices.getLocalizedValue("app_openingType_" + OM.OpeningType[el], OM.OpeningType[el])

            result.push({...vm});
        })

        return result;
    }

}

export let LocalizationServices = new _LocalizationServices();

function replaceText(text: string, object: any): string {
    if(!object)
        return text;
    
    while(text.indexOf('{{') != -1){
        var init = text.indexOf("{{");
        var end = text.indexOf("}}");

        if(init == -1 || end == -1)
            break;
        
        var textToReplace = text.substring(init, text.length - (text.length - end) + 2);
        var variableName = textToReplace.substring(2, textToReplace.length - 2);
        
        if(object[variableName] == null || object[variableName] == undefined){
            console.error("Variabile: " + variableName + " non esistente nell'oggetto");
            break;
        }

        text = text.replace(textToReplace, object[variableName]);
    }

    return text;
}