
import { mixins, Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Breadcrumb } from '@/viewModel';
import WithModel from './standard/formFieldPlugin/mixins/withModel';
import { createPreviewFromImage } from '@/utility';

@Options({
  components: { },
})
export default class SelectFile extends mixins(WithModel) {
    
    @Prop({
        default: ""
    }) imageUrl: string;

    loadedFilePublicUrl = "";

    created(){
    }

    get publicUrl(){
        if(this.loadedFilePublicUrl)
            return this.loadedFilePublicUrl;

        return this.imageUrl;
    }

    async imageChangedTemp(file: File){
        if(!file)
            return;

		this.loadedFilePublicUrl = await createPreviewFromImage(file);
        this.emitModel(file);
	}
}
