import { CommonServices } from '@/services/CommonServices';
import { StorageServices } from '@/services/StorageServices';
import router from '@/router';
import store from '@/store';
import * as OM from '@/Model';
import { AppUserClient, InternalUserClient } from './Services';

class _LoginServices {
    
    LoginCallback(token: string, refreshToken: string){
        if(!token){
            LoginServices.logout();
            router.replace('/');
            return;
        }

        StorageServices.setNewTokens(token, refreshToken);

        InternalUserClient.tokenUpgrade()
        .then( x => {
            var loggedUser = x;
            StorageServices.setLoginToken(x.token);
            StorageServices.setLoggedUser(loggedUser);
            store.state.loggedUser = loggedUser;
            store.state.selectedLanguage = loggedUser.preferredCulture;
            
            if(router.currentRoute.value.fullPath == "/")
                router.push('/dashboard');
        })
        .catch( err => {
            LoginServices.logout();
            router.replace('/');
        });

    };

    logout() {
        let prom = new Promise<void>((resolve, reject) => {
            CommonServices.destroyToken();
            this.resetLoggedUser();
            router.push("/");
            resolve();
        })
        return prom;
    }

    private resetLoggedUser() {
        var loggedUser = new OM.LoggedUserVM();
        loggedUser.preferredCulture = StorageServices.getLoggedUser().preferredCulture;
        StorageServices.setLoggedUser(loggedUser);
        store.state.loggedUser = loggedUser;
    }

}

export let LoginServices = new _LoginServices();