
import { Options, Vue } from 'vue-class-component';

@Options({
    components: {
    }
})
export default class FullHorizontalScroll extends Vue {

    element: any = null;
    mouseDown = false;
    startX = 0;
    scrollLeft = 0;

    mounted(){
        this.element = this.$refs.full_horizontal_scroll;

        // this.element.onwheel = this.scrollHorizontal;
        this.element.onmousedown = this.startDragging;
        this.element.onmouseup = this.stopDragging;
        this.element.onmouseleave = this.stopDragging;
        this.element.onmousemove = this.mouseMove;
    }

    mouseMove(event){
        event.preventDefault();

        if(!this.mouseDown) { 
            return; 
        }
        
        const x = event.pageX - this.element.offsetLeft;
        const scroll = x - this.startX;
        this.element.scrollLeft = this.scrollLeft - scroll;
    }

    scrollHorizontal(event) {
        event.preventDefault();

        if (event.deltaY > 0) 
            this.element.scrollLeft += 120;
        else 
            this.element.scrollLeft -= 120;
    }

    startDragging(eevent) {
        this.mouseDown = true;
        this.startX = eevent.pageX - this.element.offsetLeft;
        this.scrollLeft = this.element.scrollLeft;
    };

    stopDragging(event) {
        this.mouseDown = false;
    };
}
