import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "modal bg_white flex_centered column" }
const _hoisted_2 = { class: "page_padding text-center" }
const _hoisted_3 = { class: "page_padding w-100 overflow-auto" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "row mt-3 mx-0" }
const _hoisted_6 = { class: "col-4" }
const _hoisted_7 = { class: "fw_600" }
const _hoisted_8 = { class: "col-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_localized_text, {
        type: "h2",
        class: "text-center",
        localizedKey: "backoffice_AffinityStatusHistory",
        text: "Affinity Status History"
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model, (affinity) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "row",
          key: affinity.createdOn
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_localized_text, {
                  class: "fev_text6 mb-1",
                  localizedKey: "backoffice_CreatoIl",
                  text: "Creato Il"
                }),
                _createElementVNode("h4", _hoisted_7, _toDisplayString(_ctx.$filters.date(affinity.createdOn, "DD/MM/yyyy HH:mm")), 1)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_localized_text, {
                  class: "fev_text6 mb-1",
                  localizedKey: "backoffice_Stato",
                  text: "Stato"
                }),
                _createVNode(_component_localized_text, {
                  type: "h4",
                  class: "fw_600",
                  localizedKey: 'backoffice_affinitystatus_' + _ctx.replyStatus[affinity.status],
                  text: _ctx.replyStatus[affinity.status]
                }, null, 8, ["localizedKey", "text"])
              ])
            ])
          ])
        ]))
      }), 128))
    ])
  ]))
}