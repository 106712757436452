
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from "@/Model";
import replyStatusHistoryModal from '@/views/users/modals/replyStatusHistoryModal.vue';

@Options({})
export default class matchImageAndQuestions extends Vue {

    @Prop() affinityReply: OM.AffinityReply;

    replyStatus = OM.ReplyStatus;

    openHistory(){
        this.$opModal.show(replyStatusHistoryModal, {
            model: this.affinityReply.statusHistory
        })
    }

    goToUser(identifier: string){
        this.$router.push("/user-detail/" + identifier);
    }

}
