
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import filters from "@/filters"

@Options({
    components: {
    }
})
export default class Cloud extends Vue {

    @Prop() text: string;
    @Prop() time: string;

    @Prop({
        default: 'right'
    }) position: 'right' | 'left';
    
    @Prop({
        default: null
    }) profileQuestion: OM.ProfileQuestionAttached;

    @Prop({
        default: null
    }) textMessageAttached: OM.TextMessageAttached;

    created() {
    }

    get getTextFormatted(){
        return filters.getChatTextFormatted(this.text);
    }

}
