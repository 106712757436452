import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { beginModule } from '@/views/begin';
import { dashboardModule } from '@/views/dashboard';
import { usersModule } from '@/views/users';
import { waitingListModule } from '@/views/waitingList';
import { influencerReferralCodeModule } from '@/views/influencerReferralCode';
import { dictionaryModule } from '@/views/dictionary';
import { configurationModule } from '@/views/configuration';
import { questionsModule } from '@/views/questions';
import { appModalsModule } from '@/views/appModals';
import { eventsModule } from '@/views/events';
import store from '@/store';
import { activePlacesModule } from '@/views/activePlaces';
import { experiencesModule } from '@/views/experiences';
import { affinitiesModule } from '@/views/affinities';
import { chatsModule } from '@/views/chats';
import { fakeprofileModule } from '@/views/fakeProfile';

const routes: Array<RouteRecordRaw> = [
    ...beginModule.routes(),
    {
        path: '/',
        component: () => import("../views/layout.vue"),
        beforeEnter: (to, from) => {
            if(!store.state.loggedUser.identifier)
                return false
        },
        children: [
            ...dashboardModule.routes(),
            ...usersModule.routes(),
            ...fakeprofileModule.routes(),
            ...waitingListModule.routes(),
            ...influencerReferralCodeModule.routes(),
            ...dictionaryModule.routes(),
            ...questionsModule.routes(),
            ...experiencesModule.routes(),
            // ...eventsModule.routes(),
            ...configurationModule.routes(),
            ...activePlacesModule.routes(),
            ...appModalsModule.routes(),
            ...affinitiesModule.routes(),
            ...chatsModule.routes()
        ]
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router