import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28c9ed3d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex p-0" }
const _hoisted_2 = ["value", "tabindex", "readonly"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_field = _resolveComponent("select-field")!
  const _directive_localizedPlaceholder = _resolveDirective("localizedPlaceholder")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_select_field, {
      list: _ctx.telList,
      modelValue: _ctx.localPrefix,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localPrefix) = $event)),
      labelKey: "backoffice_Prefisso",
      labelValue: "Prefisso",
      class: "tel_select",
      placeholderKey: "backoffice_Prefisso",
      placeholderValue: "Prefisso",
      showIdentifier: true
    }, null, 8, ["list", "modelValue"]),
    _withDirectives(_createElementVNode("input", {
      class: "w-100 ms-2",
      type: "tel",
      value: _ctx.localValue,
      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.localValue = $event.target.value)),
      tabindex: _ctx.tabIndex,
      readonly: _ctx.readonly
    }, null, 40, _hoisted_2), [
      [_directive_localizedPlaceholder, { key: _ctx.placeholderKey, value: _ctx.placeholderValue, prevText: _ctx.placeholderPrevText, afterText: _ctx.placeholderAfterText }]
    ])
  ]))
}