
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from "@/Model";

@Options({})
export default class profileImageAndQuestions extends Vue {

    @Prop() profileQuestion: OM.ProfileQuestionVM;

}
