import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/experience-list',
            component: { render: () => h(RouterView) },
            children: [
                {
                    path: '/experience-list',
                    name: 'experience-list',
                    component: () => import("./views/experiences.vue")
                },
                {
                    path: '/experience-list/:identifier',
                    name: 'experience detail',
                    component: () => import("./views/experienceDetail.vue")
                },
            ]
        },
        {
            path: '/experience-requests',
            name: 'experience requests',
            component: () => import("./views/experienceRequests.vue")
        },
        {
            path: '/expreports',
            name: 'expreports',
            component: () => import("./views/expreports.vue")
        }
    ];
};