
import { MatchClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import PersonInfo from '@/components/personInfo.vue';
import LoveFaceShadowBar from '@/components/loveFaceShadowBar.vue';
import ReportQuestion from '@/components/reportQuestion.vue';
import ViewDescriptionModal from '../modals/viewDescriptionModal.vue';
import bodymovin from 'lottie-web';
import * as OM from '@/Model';
import { Prop, Watch } from 'vue-property-decorator';

@Options({
    components: {
        PersonInfo,
        LoveFaceShadowBar,
        ReportQuestion
    }
})
export default class FinderMatches extends Vue {

    @Prop() match: OM.NewMatchVm;

    @Watch('match')
    startLottieAnimation() {
        if(this.match && this.match.hasAnyExperience) {
            setTimeout(() => {
                var lottieExperience = bodymovin.loadAnimation({
                    container: <any>this.$refs.lottieExperience,
                    renderer: 'svg',
                    loop: true,
                    autoplay: true,
                    path: './json/experience_loop.json',
                })

                lottieExperience.play();
            }, 300);
        }
    }
    
    get affinityRateText(){
        if(this.match.affinityRateReachedFromOther != null)
            return this.$localizationService.getLocalizedValue("app_AffinityRate{{rate}}Di{{Name}}", "Affinity Rate di {{name}}: {{rate}}%", "", "", { rate: this.match.minimumAffinityRate, name: this.$filters.cutNameTooLong(this.match.personName) });   
        else
            return this.$localizationService.getLocalizedValue("app_AffinityRate{{rate}}", "Affinity Rate: {{rate}}%", "", "", { rate: this.match.minimumAffinityRate });   
    }

    goTo(identifier: string){
        var url = "/user-detail/" + identifier;

        this.$router.push(url);
    }
}
