import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b758829"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { "aria-label": "breadcrumb" }
const _hoisted_3 = { class: "breadcrumb breadcrumb-custom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.lista.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("nav", _hoisted_2, [
          _createElementVNode("ol", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lista, (breadcrumb, index) => {
              return (_openBlock(), _createElementBlock("li", {
                class: _normalizeClass(["breadcrumb-item", { active: index == _ctx.lista.length }]),
                key: index
              }, [
                _createVNode(_component_router_link, {
                  to: breadcrumb.link
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(breadcrumb.title), 1)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ], 2))
            }), 128))
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}