import { vModelText as _vModelText, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["readonly", "tabindex", "min", "max", "step"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    ref: "range",
    type: "range",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
    style: _normalizeStyle('background-size:' + (_ctx.localValue - _ctx.min) * 100 / (_ctx.max - _ctx.min) + '% 100%'),
    readonly: _ctx.readonly,
    tabindex: _ctx.tabIndex,
    min: _ctx.min,
    max: _ctx.max,
    step: _ctx.step
  }, null, 12, _hoisted_1)), [
    [_vModelText, _ctx.localValue]
  ])
}