import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/affinity-list',
            component: { render: () => h(RouterView) },
            children: [
                {
                    path: '/affinity-list',
                    name: 'affinities',
                    component: () => import("./views/affinities.vue")
                },
                {
                    path: '/affinity-list/:identifier',
                    name: 'affinity detail',
                    component: () => import("./views/affinityDetail.vue")
                },
            ]
        }
    ];
};