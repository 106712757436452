
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import LoveFaceShadowBar from '@/components/loveFaceShadowBar.vue';
import * as OM from '@/Model';

@Options({
    components: {
        LoveFaceShadowBar
    }
})
export default class ExperienceApplicant extends Vue {

    @Prop({
        default: () => new OM.ApplicantVM()
    }) applicant: OM.ApplicantVM;

    created() {}

}
