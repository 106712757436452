
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import BaseServerFilterTable from '@/components/customTable/baseServerFilterTable';
import { Prop, Watch } from 'vue-property-decorator';
import store from '@/store';

@Options({
    components: {
    }
})
export default class ExperienceListComponent extends BaseServerFilterTable {

    @Prop() userIdentifier;
    @Prop() initCallback;

    list: OM.ExperienceBackofficeListVM[] = [];
    lang: string = store.state.loggedUser.preferredCulture;

    created() {
    }

    init = function(){
        var tempRequestFilter = this.requestFilter;

        if(this.userIdentifier)
            tempRequestFilter.identifier = this.userIdentifier;

        this.initCallback(tempRequestFilter, this.setPagedResponse);
    }

    setPagedResponse(pagedResponse: OM.PagedResponseOfExperienceBackofficeListVM){
        this.totalItems = pagedResponse.totalItemsCount;
        this.list = pagedResponse.items;
    }

    rowClicked(identifier: string){
        this.$emit("rowClicked", identifier);
    }

    getStatus(approvedData: OM.ApprovedData){
        if(!approvedData)
            return this.$localizationService.getLocalizedValue("backoffice_Bozza", "Bozza");

        if(!approvedData.approvedOn && !approvedData.refusedOn)
            return this.$localizationService.getLocalizedValue("backoffice_DaApprovare", "Da Approvare");

        if(approvedData.approvedOn)
            return this.$localizationService.getLocalizedValue("backoffice_Approvata", "Approvata");

        return this.$localizationService.getLocalizedValue("backoffice_Rifiutata", "Rifiutata");
    }
}
