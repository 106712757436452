
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Chart from 'chart.js/auto';

@Options({})
export default class PieChart extends Vue {

    @Prop() dataNumbers: number[];
    @Prop() dataColors: string[];
    @Prop() dataLabels: string[];

    mounted(){
        this.pieChart();
    }

    pieChart(){
        var canvas : any = this.$refs.allUsersChart;

        var ctx = canvas.getContext('2d');
        var myChart = new Chart(ctx, {
            type: 'pie', 
            data: {
                labels: this.dataLabels,
                datasets: [{
                    label: '',
                    data: this.dataNumbers,
                    backgroundColor: this.dataColors,
                    hoverOffset: 4
                }]
            },
            options: {
                
            }
        });
        
    }
}
