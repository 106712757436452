import { vModelText as _vModelText, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b080f1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position-relative" }
const _hoisted_2 = ["tabindex"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_localizedPlaceholder = _resolveDirective("localizedPlaceholder")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      tabindex: _ctx.tabindex,
      type: "search",
      ref: "autocomplete",
      class: _normalizeClass(_ctx.inputClass),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.local) = $event)),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.emitInput && _ctx.emitInput(...args))),
      autocomplete: "chrome-off"
    }, null, 42, _hoisted_2), [
      [_vModelText, _ctx.local],
      [_directive_localizedPlaceholder, {key: _ctx.placeholderKey, value: _ctx.placeholderValue, prevText: _ctx.placeholderPrevText, afterText: _ctx.placeholderAfterText }]
    ])
  ]))
}