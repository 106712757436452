import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/configuration',
            name: 'configuration',
            component: () => import("./views/configuration.vue")
        }
    ];
};